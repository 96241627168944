import { Modal, Table } from "antd";
import { Button } from "components/Button";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { intl } from "utils/intl";
import { ColumnsType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { PolicyFileIncludingTaskId, TasksLinkPolicyPutRequest } from "mycio-openapi";
import { getTaskAllPoliciesById } from "store/tasks";

interface TLPSMInterface {
  isVisible: boolean;
  onClose: () => void;
  taskId?: number;
  onSubmitPolicies: (data: TasksLinkPolicyPutRequest) => Promise<boolean>;
}

const itemsPerPage = 5;
const TaskDrawerLinkedPoliciesSectionModal: React.FC<TLPSMInterface> = ({
  taskId,
  onClose,
  isVisible,
  onSubmitPolicies,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState<PolicyFileIncludingTaskId[]>([]);
  const [unCheckedItems, setUnCheckedItems] = useState<PolicyFileIncludingTaskId[]>([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    void dispatch(getTaskAllPoliciesById({ id: taskId, page: currentPage, limit: itemsPerPage }));
  }, [dispatch, taskId, currentPage]);

  const { TaskAllPoliciesById, TaskAllPoliciesByIdLoading, TaskUpdatePoliciesByIdLoading } =
    useAppSelector((state) => state.tasks);

  useEffect(() => {
    if (TaskAllPoliciesById) {
      const initiallyChecked =
        TaskAllPoliciesById.policy_files?.filter((policy) => policy.linked_to_task) || [];
      setCheckedItems(initiallyChecked);
    }
  }, [TaskAllPoliciesById]);

  const columns: ColumnsType<PolicyFileIncludingTaskId> = [
    {
      title: intl.formatMessage({
        id: "task-side-drawer-link-policies-table-policy-sub_type",
        defaultMessage: "Policy Type",
      }),
      dataIndex: "sub_type",
      render: (sub_type: string) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{sub_type}</div>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: "task-side-drawer-link-policies-table-policy-status",
        defaultMessage: "Policy Status",
      }),
      dataIndex: "status",
      render: (status: string) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{status}</div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: PolicyFileIncludingTaskId[]) => {
      const newlyUnchecked = checkedItems.filter(
        (item) => !selectedRows.some((row) => row.id === item.id)
      );

      setCheckedItems(selectedRows);
      setUnCheckedItems(newlyUnchecked);
    },
    selectedRowKeys: checkedItems
      ?.map((item) => item.id)
      .filter((id) => id !== undefined) as React.Key[],
  };

  const handleOnSubmitPolicies = async () => {
    const toBeLinked = checkedItems
      ?.map((item) => item.id)
      .filter((id): id is number => id !== undefined);
    const toBeUnlinked = unCheckedItems
      ?.map((item) => item.id)
      .filter((id): id is number => id !== undefined);

    await onSubmitPolicies({
      task_id: taskId as number,
      to_be_linked: toBeLinked,
      to_be_unlinked: toBeUnlinked,
    });
  };

  return (
    <Modal
      title={
        <div className="flex items-center justify-between">
          {intl.formatMessage({
            id: "task-side-drawer-link-policies-modal-name",
            defaultMessage: "Update Document",
          })}
        </div>
      }
      onCancel={onClose}
      visible={isVisible}
      closable={false}
      footer={
        <div className="flex w-full justify-end  ">
          <Button size="small" variant="secondary" type="button" className="mt-2" onClick={onClose}>
            <FormattedMessage
              id="task-side-drawer-link-policies-modal-cancel"
              defaultMessage="Cancel"
            />
          </Button>

          <Button
            size="small"
            variant="tertiary"
            type="submit"
            className="ml-3 mt-2"
            onClick={handleOnSubmitPolicies}
            isLoading={TaskUpdatePoliciesByIdLoading}
          >
            <FormattedMessage id="vendor-document-upload-modal-submit" defaultMessage="Submit" />
          </Button>
        </div>
      }
    >
      <Table
        className="border border-skeldus-black-50"
        dataSource={TaskAllPoliciesById?.policy_files}
        loading={TaskAllPoliciesByIdLoading}
        columns={columns}
        rowKey="id"
        size="small"
        pagination={{
          style: { marginRight: 30 },
          current: currentPage,
          pageSize: itemsPerPage,
          onChange: (page) => setCurrentPage(page),
          total: TaskAllPoliciesById.total,
        }}
        rowSelection={rowSelection}
      />
    </Modal>
  );
};

export default TaskDrawerLinkedPoliciesSectionModal;
