import React from "react";
import { TaskDrawerSectionProps } from "components/TaskDrawer/TaskDrawer.interface";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import { ListIcon } from "components/icons";
import { iconDefaultClasses } from "./TaskDrawerEvidenceSection";

const TaskDrawerRemediationSection = ({ task }: TaskDrawerSectionProps) => (
  <section>
    {task?.control?.remediation && (
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "side-drawer-remediation-title",
          defaultMessage: "Remediation",
        })}
        icon={<ListIcon className={iconDefaultClasses} />}
      >
        <div dangerouslySetInnerHTML={{ __html: task?.control.remediation }} />
      </SideDrawerAccordion>
    )}
  </section>
);

export default TaskDrawerRemediationSection;
