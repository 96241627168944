import React from "react";
import NoData from "components/images/NoData";
import classNames from "classnames";
import Link from "next/link";
import { INoDataMessage, Type } from "./NoDataMessage.interface";

const NoDataMessage: React.FC<INoDataMessage> = ({ type, className }) => (
  <div className="flex h-2/5 flex-col items-center justify-center">
    <NoData className={classNames(className)} />
    <p className="mt-10 text-lg">
      {type === Type.Devices && (
        <span>
          {" "}
          Setup an{" "}
          <Link href="/integrations/MyCIOAgent">
            <a className="text-[#2779f6] underline">integration</a>
          </Link>{" "}
          to start seeing data here.
        </span>
      )}
      {type === Type.CloudAssets && (
        <span>
          {" "}
          Setup an{" "}
          <Link href="/integrations/AWS">
            <a className="text-[#2779f6] underline">integration</a>
          </Link>{" "}
          to start seeing data here.
        </span>
      )}
      {type === Type.Tasks && <span>Tasks are being created. Check back again shortly.</span>}
    </p>
  </div>
);

export default NoDataMessage;
