import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import { ListIcon, UnlinkIcon } from "components/icons";
import { Button } from "components/Button";
import { Table, Tooltip, Button as IconButton } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getTaskLinkedPoliciesById, updateTaskPoliciesById } from "store/tasks";
import { PolicyFile, PolicyStatus, TasksLinkPolicyPutRequest } from "mycio-openapi";
import { iconDefaultClasses } from "./TaskDrawerEvidenceSection";
import TaskDrawerLinkedPoliciesSectionModal from "./TaskDrawerLinkedPoliciesSectionModal";
import { TaskDrawerSectionProps } from "./TaskDrawer.interface";

const itemsPerPage = 5;
const TaskDrawerLinkedPoliciesSection = ({ task }: TaskDrawerSectionProps) => {
  const dispatch = useAppDispatch();
  const { push } = useRouter();

  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    void dispatch(
      getTaskLinkedPoliciesById({ id: task.id, page: currentPage, limit: itemsPerPage })
    );
  }, [dispatch, currentPage, task]);

  const { TaskLinkedPoliciesById, TaskLinkedPoliciesByIdLoading } = useAppSelector(
    (state) => state.tasks
  );

  const columns: ColumnsType<PolicyFile> = [
    {
      title: intl.formatMessage({
        id: "task-side-drawer-link-policies-table-policy-type",
        defaultMessage: "Policy Type",
      }),
      dataIndex: "sub_type",
      render: (sub_type: string) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{sub_type}</div>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: "task-side-drawer-link-policies-table-policy-status",
        defaultMessage: "Policy Status",
      }),
      dataIndex: "status",
      render: (status: PolicyStatus) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{status}</div>
        </div>
      ),
    },
    {
      width: "8%",
      render: () => (
        <Tooltip title="Unlink Policy">
          <IconButton
            shape="circle"
            icon={<UnlinkIcon />}
            onClick={() => {}}
            className="flex  items-center justify-center "
          />
        </Tooltip>
      ),
    },
  ];

  const handleRow = (row: PolicyFile) => ({
    onClick: () => {
      if (row && row.id) {
        void push(`/controls/${row.id}`);
      }
    },
  });

  const handleLinkUnlinkPolicies = async (policies: TasksLinkPolicyPutRequest) => {
    const result = await dispatch(updateTaskPoliciesById(policies))
      .unwrap()
      .then(() => true);
    if (result) {
      getTaskLinkedPoliciesById({ id: task.id, page: currentPage, limit: itemsPerPage });
    }
    return result;
  };

  return (
    <section>
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "task-side-drawer-linked-policies-title",
          defaultMessage: "Linked Policies",
        })}
        icon={<ListIcon className={iconDefaultClasses} />}
      >
        <div className="flex items-center justify-end border-[#efefef]">
          <TaskDrawerLinkedPoliciesSectionModal
            isVisible={modalVisibility}
            onClose={() => setModalVisibility(false)}
            taskId={task.id}
            onSubmitPolicies={handleLinkUnlinkPolicies}
          />
          <Button
            size="small"
            type="button"
            variant="secondary"
            className="text-xs"
            onClick={() => setModalVisibility(true)}
          >
            {intl.formatMessage({
              id: "task-side-drawer-link-policies-button",
              defaultMessage: "Link Policies",
            })}
          </Button>
        </div>
        <div className="drawer-table mt-4">
          <Table
            className="border border-skeldus-black-50"
            dataSource={TaskLinkedPoliciesById?.policy_files}
            loading={TaskLinkedPoliciesByIdLoading}
            columns={columns}
            rowKey="id"
            size="small"
            pagination={{
              style: { marginRight: 30 },
              current: currentPage,
              pageSize: itemsPerPage,
              onChange: (page) => setCurrentPage(page),
              total: TaskLinkedPoliciesById.total,
            }}
            onRow={handleRow}
          />
        </div>
      </SideDrawerAccordion>
    </section>
  );
};

export default TaskDrawerLinkedPoliciesSection;
