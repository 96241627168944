import classNames from "classnames";
import { ButtonLoader } from "components/ButtonLoader";
import { useAppSelector } from "store/hooks";
import { selectAvatar } from "store/users";

interface AvatarProps {
  avatar?: string;
  fallbackUserName?: string;
  fallbackCompanyName?: string;
  size: "tiny" | "small" | "large";
  isLoading?: boolean;
  isTag?: boolean;
  UUID?: number;
}

const Avatar = ({
  avatar,
  fallbackUserName,
  fallbackCompanyName,
  size,
  isTag,
  isLoading,
  UUID,
  ...props
}: AvatarProps) => {
  const avatarStyles = {
    common: "rounded-full skeldus-avatar",
    tiny: "h-6 w-6 text-xs border-none",
    small: "h-8 w-8 text-xs border-none",
    large: "h-[75px] w-20 text-2xl border-none",
    isLoading: "text-[10px]!important",
  };

  const fallbackStyles = "flex shrink-0 items-center justify-center font-semibold uppercase";

  const fallbackColors = {
    red: "bg-skeldus-main-50 border border-skeldus-main-150 text-skeldus-main-500",
    blue: "bg-[#248b6e] border border-skeldus-grey-150 text-[#fff]",
  };

  const avatarUrl = useAppSelector((state) => selectAvatar(state, UUID));

  const generateAlt = () => {
    if (fallbackUserName) return `${fallbackUserName} avatar`;
    if (fallbackCompanyName) return `${fallbackCompanyName} avatar`;
    return "";
  };

  const generateInitials = () => {
    if (fallbackUserName) return fallbackUserName[0];
    if (fallbackCompanyName) return fallbackCompanyName[0];
    return "";
  };

  const AvatarIcon = () => {
    if (avatar || avatarUrl) {
      return (
        <div {...props} className={classNames(avatarStyles[size], "skeldus-avatar")}>
          <img
            className={classNames(
              isLoading ? avatarStyles.isLoading : "",
              "block h-full w-full object-cover",
              avatarStyles.common,
              isLoading ? "bg-black" : ""
            )}
            src={avatar || avatarUrl}
            alt={isLoading ? generateAlt() : ""}
          />
        </div>
      );
    }
    return (
      <div
        {...props}
        className={classNames(
          avatarStyles[size],
          avatarStyles.common,
          fallbackStyles,
          fallbackUserName ? fallbackColors.blue : fallbackColors.red
        )}
      >
        {generateInitials()}
      </div>
    );
  };

  const AvatarTag = () => (
    <div className="group-hover: inline-flex items-center space-x-2 truncate rounded-md border border-skeldus-black-50 bg-[#f3f3f4]  px-3 py-1 pl-2">
      <AvatarIcon />
      <span className=" text-sm text-skeldus-black-600">{fallbackUserName}</span>
    </div>
  );

  if (isLoading) {
    return (
      <div
        {...props}
        className={classNames(avatarStyles[size], "flex items-center justify-center")}
      >
        <ButtonLoader />
      </div>
    );
  }

  return <>{isTag ? <AvatarTag /> : <AvatarIcon />}</>;
};

export default Avatar;
