import { useState } from "react";
import { ISideDrawerAccordion } from "./SideDrawer.interface";
import SideDrawerAccordionHeader from "./SideDrawerAccordionHeader";

const SideDrawerAccordion = ({
  accordionKey,
  children,
  icon,
  notice,
  bgColor,
  isClosed = true,
}: ISideDrawerAccordion) => {
  const [toggle, setToggle] = useState(isClosed);

  function getBackgroundColor() {
    let color = "";
    if (bgColor) {
      color = bgColor;
    } else {
      color = "bg-[#F3F7FA]";
    }
    return color;
  }

  return (
    <div className={`mb-2 rounded border border-skeldus-black-50 p-2 ${getBackgroundColor()}`}>
      <SideDrawerAccordionHeader
        toggle={toggle}
        setToggle={setToggle}
        accordionKey={accordionKey}
        icon={icon}
        notice={notice}
      />
      {
        <div
          className={`duration-400 h-auto overflow-hidden transition-all ease-in-out ${
            toggle ? "max-h-0" : "max-h-[48rem]"
          }`}
        >
          <div className={` mt-3 p-2`}>{children}</div>
        </div>
      }
    </div>
  );
};

export default SideDrawerAccordion;
