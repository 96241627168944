import { Tooltip } from "antd";
import classNames from "classnames";
import { FeatureFlag } from "components/FeatureFlag";
import Link from "next/link";
import { useRouter } from "next/router";
import { selectSidebarOpen } from "store/application";
import { useAppSelector } from "store/hooks";
import { NavMenuItem } from "./SidebarMenu.interfaces";

interface SidebarMenuGroupProps {
  groupLabel?: string;
  items: NavMenuItem[];
}

interface SidebarItemProps {
  item: NavMenuItem;
}

const SidebarItem = ({ item }: SidebarItemProps) => {
  const sidebarOpen = useAppSelector((state) => selectSidebarOpen(state));

  const baseLinkStyles = `flex flex-row items-center font-medium hover:text-skeldus-main-500 ${
    sidebarOpen ? "px-8" : "justify-center"
  } gap-2 h-10 text-sm transition duration-400`;
  const currentLinkStyles = `bg-skeldus-main-500 text-skeldus-white hover:text-skeldus-black-50 ${
    sidebarOpen ? "w-[90%] rounded-tr-full rounded-br-full" : "rounded"
  }`;
  const router = useRouter();

  return (
    <Link href={item.href}>
      <a
        className={classNames(
          baseLinkStyles,
          router.pathname === "/" && item.href === "/" && currentLinkStyles,
          item.href !== "/" &&
            router.pathname.includes(item.href.split("/")[1]) &&
            currentLinkStyles
        )}
      >
        {sidebarOpen ? (
          <>
            <span className="max-h-[16px] max-w-[16px]">{item.icon}</span>
            {sidebarOpen && item.label}
          </>
        ) : (
          <Tooltip
            overlayInnerStyle={{ marginLeft: 10, color: "black" }}
            color="white"
            title={item.label}
            placement="right"
          >
            <span className="max-h-[16px] max-w-[16px]">{item.icon}</span>
            {sidebarOpen && item.label}
          </Tooltip>
        )}
      </a>
    </Link>
  );
};

const SidebarMenuGroup = ({ groupLabel, items }: SidebarMenuGroupProps) => {
  const sidebarOpen = useAppSelector((state) => selectSidebarOpen(state));

  return (
    <div className={`flex flex-col`}>
      {groupLabel && (
        <h4
          className={`text-skeldus-black-300 ${
            sidebarOpen ? "text-ellipsis text-xs" : "my-8 h-px bg-[#f0f0f0]"
          } mb-2 mt-4 overflow-clip px-4 uppercase`}
        >
          {groupLabel}
        </h4>
      )}
      <div className={sidebarOpen ? "" : "mx-2 my-2 rounded"}>
        {items.map((item) =>
          item.featureFlag ? (
            <FeatureFlag key={item.href} feature={item.featureFlag}>
              <SidebarItem item={item} />
            </FeatureFlag>
          ) : (
            <SidebarItem key={item.href} item={item} />
          )
        )}
      </div>
    </div>
  );
};

export default SidebarMenuGroup;
