import { Tabs, Button as AntButton, Pagination, Menu, Dropdown, Tooltip } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, MoreOutlined } from "@ant-design/icons";
import isEqual from "lodash/isEqual";
import { PageContainer } from "components/PageContainer";
import { Search } from "components/Search";
import { Select } from "components/Select";
import { TaskItem } from "components/TaskItem";
import { TaskDrawer } from "components/TaskDrawer";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getAllTasks,
  getTaskCsvRoutee,
  getTasks,
  resetSelectedTask,
  updateTask,
} from "store/tasks";
import { selectAllUsers } from "store/users";
import { compareAsc, compareDesc } from "date-fns";
import { taskStatusOptions, sortByOptions } from "utils/data/options";
import { FrontendTask, SortByOption } from "utils/data/interfaces";
import { NoDataMessage } from "components/NoDataMessage";
import { Spinner } from "components/Spinner";
import { Type } from "components/NoDataMessage/NoDataMessage.interface";
import { TaskStatusEnum } from "mycio-openapi";

const itemsPerPage = 7;

const Tasks = () => {
  const dispatch = useAppDispatch();
  const [currentTasksPage, setCurrentTasksPage] = useState(1);
  const { total, tasks, loading } = useAppSelector((state) => getAllTasks(state));
  const { TaskCsvRoute, TaskCsvRouteLoading } = useAppSelector((state) => state.tasks);
  const users = useAppSelector((state) => selectAllUsers(state));
  const [selectedTask, setSelectedTask] = useState<FrontendTask>();
  const intl = useIntl();

  const [searchFilter, setSearchFilter] = useState("");
  const [ownerFilter, setOwnerFilter] = useState<number>();
  const [statusFilter, setStatusFilter] = useState<TaskStatusEnum>();
  const [sortByFilter, setSortByFilter] = useState<SortByOption>();
  const [sortAscending, setSortAscending] = useState(true);

  useEffect(() => {
    void dispatch(getTasks({ page: currentTasksPage, limit: itemsPerPage }));
  }, [dispatch, currentTasksPage]);

  useEffect(() => {
    void dispatch(getTaskCsvRoutee());
  }, []);

  const saveSelectedTaskWhenChangesMade = () => {
    if (selectedTask) {
      const updatedAt = new Date().toISOString();

      const globalStateTask = tasks.find((task) => task.id === selectedTask.id);
      if (!isEqual(selectedTask, globalStateTask))
        void dispatch(
          updateTask({
            task: selectedTask,
            updatedAt,
          })
        );
    }
  };

  const fulfillsNameFilter = (task: FrontendTask): boolean =>
    task.name.toLowerCase().includes(searchFilter.toLowerCase());

  const fulfillsOwnerFilter = (task: FrontendTask): boolean => {
    if (!ownerFilter) return true;
    return task.Owners ? task.Owners?.some((owner) => owner.user_id === ownerFilter) : false;
  };

  const fulfillsStatusFilter = (task: FrontendTask): boolean =>
    statusFilter ? task.status === statusFilter : true;

  const fulfillsAllFilters = (task: FrontendTask): boolean =>
    fulfillsNameFilter(task) && fulfillsOwnerFilter(task) && fulfillsStatusFilter(task);

  const compareByStatus = (a: FrontendTask, b: FrontendTask): number => {
    const getTaskStatus = (status: TaskStatusEnum) => {
      switch (status) {
        case "Completed":
          return 0;
        case "Not Started":
          return 1;
        case "In Progress":
          return 2;
        case "Overdue":
          return 3;
        default:
          return 4;
      }
    };
    if (getTaskStatus(a.status) > getTaskStatus(b.status)) return sortAscending ? -1 : 1;
    if (getTaskStatus(a.status) === getTaskStatus(b.status)) return 0;
    return sortAscending ? 1 : -1;
  };

  const compareByDueDate = (a: FrontendTask, b: FrontendTask): number => {
    if (a.due_date) {
      if (b.due_date) {
        return sortAscending
          ? compareAsc(new Date(a.due_date), new Date(b.due_date))
          : compareDesc(new Date(a.due_date), new Date(b.due_date));
      }
      return sortAscending ? -1 : 1;
    }
    if (sortAscending) {
      return b.due_date ? 1 : 0;
    }
    return b.due_date ? -1 : 0;
  };

  const compareTasks = (a: FrontendTask, b: FrontendTask): number => {
    if (sortByFilter === SortByOption.Status) return compareByStatus(a, b);
    if (sortByFilter === SortByOption.DueDate) return compareByDueDate(a, b);
    return 0;
  };

  // const buildTreeData = (evidence: TaggedEvidence) => {
  //   const treeData: EvidenceTreeNode[] = [];
  //   Object.keys(evidence).forEach((key, id) => {
  //     const evidenceArray: { title: string; key: string; icon: React.ReactElement }[] = [];
  //     evidence[key].forEach((evidenceItem, evidenceUUID) => {
  //       evidenceArray.push({
  //         title: evidenceItem.Filename,
  //         key: `${id}-${evidenceUUID}`,
  //         icon: <FileSearchOutlined style={{ color: "#2982d6" }} />,
  //       });
  //     });
  //     const evidenceLength = evidenceArray.length;
  //     treeData.push({
  //       title: key,
  //       key: id,
  //       children: evidenceArray,
  //       selectable: false,
  //       icon:
  //         evidenceLength === 0 ? (
  //           <FolderOutlined style={{ color: "#3C50BE" }} />
  //         ) : (
  //           <FolderOutlined style={{ color: "#2982d6" }} />
  //         ),
  //     });
  //   });
  //   treeData.sort((a, b) => parseFloat(a.title.split(" ")[0]) - parseFloat(b.title.split(" ")[0]));
  //   return treeData;
  // };

  // const treeData = buildTreeData(taggedEvidence);
  // const sortedEvidence = Object.entries(taggedEvidence).sort(
  //   ([a], [b]) => parseFloat(a.split(" ")[0]) - parseFloat(b.split(" ")[0])
  // );

  const selectStyles = "flex-grow ";

  const menu = (
    <Menu className="w-full">
      <Menu.Item key="download" disabled={TaskCsvRouteLoading}>
        <a href={TaskCsvRoute?.url} rel="noopener noreferrer">
          <FormattedMessage id="tasks-export-csv-button" defaultMessage="Export as CSV" />
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageContainer title="Tasks">
      <h1 className="page-title">
        <FormattedMessage id="tasks-title" description="tasks page title" defaultMessage="Tasks" />
      </h1>
      <Tabs className="relative h-full">
        <Tabs.TabPane tab="Tasks" key="tasks">
          <section className="mb-4 flex flex-wrap items-center justify-between gap-3 xl:gap-4">
            <Search
              className="duration-400 h-[34px] flex-grow border-skeldus-black-150 transition hover:border-skeldus-main-500 focus:outline-none lg:w-[110px] xl:w-32 2xl:w-48"
              inputClassName="placeholder:text-[#bfbfbf] placeholder:"
              placeholder={intl.formatMessage({
                id: "dashoard-task-search-placeholder",
                defaultMessage: "Search...",
              })}
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              onClear={() => setSearchFilter("")}
            />
            <Select
              className={selectStyles}
              prefix="Owner"
              showSearch
              allowClear
              options={
                users?.users?.map((user) => ({
                  value: user.id?.toString() as string,
                  label: user.name,
                })) || []
              }
              onChange={(value) => {
                setOwnerFilter(value as number);
              }}
              filterOption={(input, option) =>
                (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
              }
              onClear={() => setOwnerFilter(undefined)}
            />
            <Select
              className={selectStyles}
              prefix="Status"
              options={taskStatusOptions}
              showSearch
              allowClear
              onChange={(value) => {
                setStatusFilter(value as TaskStatusEnum);
              }}
              onClear={() => setStatusFilter(undefined)}
            />
            <span className="flex flex-grow items-center gap-3 xl:gap-4">
              <Select
                className={selectStyles}
                prefix="Sort By"
                options={sortByOptions}
                showSearch
                allowClear
                onChange={(value) => {
                  setSortByFilter(value as SortByOption);
                }}
                onClear={() => setSortByFilter(undefined)}
              />
              <AntButton
                className="skeldus-input bg-skeldus-white"
                onClick={() => setSortAscending((prevState) => !prevState)}
                disabled={!sortByFilter}
              >
                {sortAscending ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </AntButton>
            </span>
            {/* <Button
              variant="primary"
              size="small"
              className="h-[34px]"
              onClick={() => setSelectedTask(newTask)}
            >
              Add task
            </Button> */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <Tooltip title="More actions">
                <AntButton type="default" shape="circle" icon={<MoreOutlined />} />
              </Tooltip>
            </Dropdown>
          </section>
          <section className="mt-4 flex flex-col gap-3">
            {loading && tasks.length === 0 ? (
              <Spinner />
            ) : tasks.length !== 0 ? (
              tasks
                .filter(fulfillsAllFilters)
                .sort(compareTasks)
                .map((task) => (
                  // TODO: onClick should be back once update task is done on the backend
                  <TaskItem key={task.id} {...task} onClick={() => setSelectedTask(task)} />
                ))
            ) : (
              <NoDataMessage type={Type.Tasks} className="mt-16 h-1/3 w-1/3" />
            )}
          </section>
          {total && total >= tasks.length && (
            <div className="mt-4 flex justify-end">
              <Pagination
                current={currentTasksPage}
                total={total}
                pageSize={itemsPerPage}
                onChange={(page) => setCurrentTasksPage(page)}
              />
            </div>
          )}
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Evidence" key="evidence">
          <div className="rounded border border-skeldus-black-50 bg-skeldus-white p-4 shadow">
            <Tree
              className="skeldus-tree"
              treeData={treeData}
              showLine={{ showLeafIcon: false }}
              switcherIcon={<DownOutlined />}
              showIcon
              onSelect={(_, event) => {
                const index = event.node.key.toString().split("-");
                const title = sortedEvidence[Number(index[0])][0];
                const evidenceUUID = taggedEvidence[title][Number(index[1])].UUID;
                if (evidenceUUID && process.env.NEXT_PUBLIC_MYCIO_API)
                  downloadEvidence(
                    `${process.env.NEXT_PUBLIC_MYCIO_API}/files/evidence/${evidenceUUID}`,
                    token
                  );
              }}
            />
          </div>
        </Tabs.TabPane> */}
      </Tabs>

      {selectedTask && (
        <TaskDrawer
          task={selectedTask}
          onClose={() => {
            saveSelectedTaskWhenChangesMade();
            dispatch(resetSelectedTask());
            setSelectedTask(undefined);
          }}
          onEdit={(taskFields) => {
            setSelectedTask({ ...selectedTask, ...taskFields } as FrontendTask);
          }}
        />
      )}
    </PageContainer>
  );
};

export default Tasks;
