export enum Type {
  Tasks,
  Devices,
  CloudAssets,
}

export interface INoDataMessage {
  type?: Type;
  className?: string;
}
