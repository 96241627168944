import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import EvidenceByAssetsIcon from "components/icons/EvidenceByAssetsIcon";
import { Image, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getTaskEvidenceByAssets } from "store/tasks";
import { TaskAsset, TaskAssetAsset, TaskAssetEvidencesInner } from "mycio-openapi";
import { iconDefaultClasses } from "./TaskDrawerEvidenceSection";

const itemsPerPage = 5;

const blank = "--";

const TaskDrawerEvidenceByAssetsSection = ({ taskId }: { taskId?: number }) => {
  const [currentEvidencesPage, setCurrentEvidencesPage] = React.useState<number>(1);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(
      getTaskEvidenceByAssets({ id: taskId, page: currentEvidencesPage, limit: itemsPerPage })
    );
  }, []);

  const { TaskEvidenceByAssets, TaskEvidenceByAssetsLoading } = useAppSelector(
    (state) => state.tasks
  );

  const columns: ColumnsType<TaskAsset> = [
    {
      title: intl.formatMessage({
        id: "Tasks-Evidence-Asset-table-icon",
        defaultMessage: "Icon",
      }),
      width: "5%",
      dataIndex: "TaskAssetAsset",
      render: (record: TaskAssetAsset) =>
        record?.asset_icon && (
          <Tooltip title={record?.asset_icon} placement="bottom">
            <Image
              src={record.asset_icon}
              className="h-[24px] w-[24px]"
              alt={record.asset_icon}
              preview={false}
            />
          </Tooltip>
        ),
    },
    {
      title: intl.formatMessage({
        id: "Tasks-Evidence-Asset-table-name",
        defaultMessage: "Name",
      }),
      width: "11%",
      dataIndex: "TaskAssetAsset",
      render: (record: TaskAssetAsset) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{record?.title ?? blank}</div>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: "Tasks-Evidence-Asset-table-type",
        defaultMessage: "Type",
      }),
      width: "11%",
      dataIndex: "TaskAssetAsset",
      render: (record: TaskAssetAsset) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{record?.asset_type ?? blank}</div>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: "Tasks-Evidence-Asset-table-evidence",
        defaultMessage: "Evidence",
      }),
      width: "11%",
      dataIndex: "TaskAssetEvidencesInner",
      render: (record: TaskAssetEvidencesInner) => (
        <div className="my-1">
          <div className="my-1 text-skeldus-black-600">{record?.name ?? blank}</div>
        </div>
      ),
    },
  ];

  return (
    <section>
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "side-drawer-evidence-by-assets-title",
          defaultMessage: "Evidence by assets",
        })}
        icon={<EvidenceByAssetsIcon className={iconDefaultClasses} />}
      >
        <Table
          dataSource={TaskEvidenceByAssets.task_assets}
          loading={TaskEvidenceByAssetsLoading}
          columns={columns}
          pagination={{
            style: { marginRight: 30 },
            current: currentEvidencesPage,
            total: TaskEvidenceByAssets.total,
            pageSize: itemsPerPage,
            onChange: (page) => setCurrentEvidencesPage(page),
          }}
          rowKey="id"
          className="ant-table border border-skeldus-black-50 shadow-none"
        />
      </SideDrawerAccordion>
    </section>
  );
};

export default TaskDrawerEvidenceByAssetsSection;
