import { Drawer, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { EditTwoTone } from "@ant-design/icons";
import { TaskDrawerProps } from "components/TaskDrawer/TaskDrawer.interface";
import TaskDrawerEvidenceSection from "components/TaskDrawer/TaskDrawerEvidenceSection";
import TaskDrawerAdditionalInfoSection from "components/TaskDrawer/TaskDrawerAdditionalInfoSection";
import TaskDrawerDetailsSection from "components/TaskDrawer/TaskDrawerDetailsSection";
import TaskDrawerDescriptionSection from "components/TaskDrawer/TaskDrawerDescriptionSection";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Button } from "components/Button";
import { useRouter } from "next/router";
import { intl } from "utils/intl";
import { getTaskById, refreshTaskDetails } from "store/tasks";
import LoadingIcon from "components/icons/loading";
import RefreshIcon from "components/icons/refresh";
import TaskDrawerRequirementsSection from "./TaskDrawerRequirementsSection";
import TaskDrawerAdditionalEvidenceSection from "./TaskDrawerAdditionalEvidenceSection";
import TaskDrawerRemediationSection from "./TaskDrawerRemediationSection";
import TaskDrawerEvidenceByAssetsSection from "./TaskDrawerEvidenceByAssetsSection";
import TaskDrawerLinkedPoliciesSection from "./TaskDrawerLinkedPoliciesSection";

const TaskDrawer = ({ task, onClose, onEdit }: TaskDrawerProps) => {
  const { selectedTask, loadingTaskCheck } = useAppSelector((state) => state.tasks);
  const dispatch = useAppDispatch();
  const { push } = useRouter();

  const refreshTask = useCallback(() => {
    if (task.id) {
      void dispatch(refreshTaskDetails(task.id));
    }
  }, [task.id, dispatch]);

  useEffect(() => {
    if (task.id) {
      void dispatch(getTaskById(task.id));
    }
  }, [task.id, dispatch]);

  const handleGoToControlsButton = () => {
    if (selectedTask?.control?.id) {
      void push(`controls/${selectedTask?.control?.id}`);
    }
  };

  return typeof selectedTask === "object" && Object.keys(selectedTask).length ? (
    <Drawer
      title={
        <div className="flex w-full items-center justify-between gap-2">
          <Typography.Paragraph
            editable={{ icon: <EditTwoTone />, onChange: (name: string) => onEdit({ name }) }}
            className="my-auto flex items-center justify-start gap-2"
          >
            {task.name}
          </Typography.Paragraph>
          <div className="flex items-center gap-4">
            <button className="my-auto h-4 w-4 items-center rounded-full p-1" onClick={refreshTask}>
              {loadingTaskCheck ? <LoadingIcon /> : <RefreshIcon />}
            </button>
            {selectedTask.control?.id && (
              <Button size="small" variant="primary" onClick={handleGoToControlsButton}>
                {intl.formatMessage({
                  id: "task-drawer-go-to-controls-button",
                  defaultMessage: "Go to control",
                })}
              </Button>
            )}
          </div>
        </div>
      }
      width={"42%"}
      visible
      bodyStyle={{ paddingBottom: 80 }}
      onClose={onClose}
      closable={false}
    >
      <TaskDrawerDetailsSection task={selectedTask} onEdit={onEdit} />
      <div className="mb-4 h-px bg-[#f0f0f0]"></div>
      <TaskDrawerRequirementsSection task={selectedTask} />

      <TaskDrawerDescriptionSection task={selectedTask} onEdit={onEdit} />
      <TaskDrawerEvidenceSection task={selectedTask} onEdit={onEdit} />
      <TaskDrawerAdditionalEvidenceSection task={task} />
      <TaskDrawerAdditionalInfoSection task={selectedTask} onEdit={onEdit} />
      <TaskDrawerRemediationSection task={selectedTask} />
      <TaskDrawerEvidenceByAssetsSection taskId={task.id} />
      <TaskDrawerLinkedPoliciesSection task={selectedTask} />
    </Drawer>
  ) : (
    <></>
  );
};

export default TaskDrawer;
