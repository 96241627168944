import { SortByOption, TaskFrequency, TaskStatus } from "utils/data/interfaces";

import { intl } from "utils/intl";

export const frequencyOptions = [
  {
    value: TaskFrequency.None,
    label: intl.formatMessage({
      id: "select-frequency-option-none",
      defaultMessage: "None",
    }),
  },
  {
    value: TaskFrequency.Monthly,
    label: intl.formatMessage({
      id: "select-frequency-option-monthly",
      defaultMessage: "Monthly",
    }),
  },
  {
    value: TaskFrequency.Biannually,
    label: intl.formatMessage({
      id: "select-frequency-option-biannually",
      defaultMessage: "Biannually",
    }),
  },
  {
    value: TaskFrequency.Annually,
    label: intl.formatMessage({
      id: "select-frequency-option-annually",
      defaultMessage: "Annually",
    }),
  },
];

export const taskStatusOptions = [
  {
    value: TaskStatus.Completed,
    label: intl.formatMessage({
      id: "tasks-status-select-complete",
      defaultMessage: "Complete",
    }),
  },
  {
    value: TaskStatus.NotStarted,
    label: intl.formatMessage({
      id: "tasks-status-select-not-started",
      defaultMessage: "Not Started",
    }),
  },
  {
    value: TaskStatus.InProgress,
    label: intl.formatMessage({
      id: "tasks-status-select-in-progress",
      defaultMessage: "In Progress",
    }),
  },
];

export const sortByOptions = [
  {
    value: SortByOption.Status,
    label: intl.formatMessage({
      id: "tasks-sortby-select-status",
      defaultMessage: "Status",
    }),
  },
  {
    value: SortByOption.DueDate,
    label: intl.formatMessage({
      id: "tasks-sortby-select-due-daye",
      defaultMessage: "Due Date",
    }),
  },
];
