import {
  Avatar as antAvatar,
  //  Tooltip
} from "antd";
import classNames from "classnames";
import { TaskProps } from "components/TaskItem/Task.interface";
import { formatDistanceToNow, isPast } from "date-fns";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TaskStatusEnum } from "mycio-openapi";
// import { Avatar } from "components/Avatar";

const TaskItem = ({ name, status, due_date, Owners, onClick }: TaskProps) => {
  const isOverdue = due_date ? isPast(new Date(due_date)) : false;

  return (
    <button
      onClick={onClick}
      className={classNames(
        "task-btn",
        status === TaskStatusEnum.InProgress && "task-btn--in-progress",
        isOverdue &&
          (status === TaskStatusEnum.InProgress || status === TaskStatusEnum.NotStarted) &&
          "task-btn--overdue"
      )}
    >
      <span className="flex h-10 flex-col items-start justify-center space-y-1">
        <span
          className={classNames(
            " text-skeldus-black-700",
            status === TaskStatusEnum.Completed && "text-skeldus-black-400"
          )}
        >
          {name}
        </span>
        {(status === TaskStatusEnum.InProgress || status === TaskStatusEnum.NotStarted) && (
          <span className="text-sm  text-skeldus-black-400">
            {due_date && (
              <span
                className={
                  isOverdue ? "text-xs text-skeldus-main-500" : "text-xs text-skeldus-yellow-500"
                }
              >
                <FormattedMessage
                  id="task-item-due"
                  defaultMessage="Due: {date}"
                  values={{ date: formatDistanceToNow(new Date(due_date), { addSuffix: true }) }}
                />
              </span>
            )}
          </span>
        )}
        {status === TaskStatusEnum.Completed && (
          <span className="text-xs  text-skeldus-black-400">
            <FormattedMessage id="task-item-completed" defaultMessage="Completed" />
          </span>
        )}
      </span>
      {Owners && (
        <antAvatar.Group maxCount={2} className="ml-auto">
          {/*
          TODO - Lint Errors

          {Owners.map((x, xid) => (
            <Tooltip key={xid} title={x.user_name} placement="top">
              <Avatar size="small" fallbackUserName={x.user_name} UUID={x.user_name} />
            </Tooltip>
          ))} */}
        </antAvatar.Group>
      )}
    </button>
  );
};

export default TaskItem;
