import classNames from "classnames";
import { CrossIcon, SearchIcon } from "components/icons";
import React from "react";

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  onClear?: () => void;
  typeIcon?: "revers";
}

enum TypeSearchIcon {
  left = "revers",
}
const Search = ({ className, value, onClear, inputClassName, typeIcon, ...props }: SearchProps) => (
  <span className={classNames("input w-60", className)}>
    {typeIcon === TypeSearchIcon.left && !value && (
      <SearchIcon className="h-4 w-4 shrink-0 text-skeldus-black-150" />
    )}
    <input
      className={classNames(
        "box-border w-full placeholder:text-skeldus-black-400 focus-visible:outline-none",
        inputClassName
      )}
      value={value}
      {...props}
    />
    {onClear && value && (
      <button onClick={onClear}>
        <CrossIcon className="h-3 w-3 shrink-0" />
      </button>
    )}
    {!typeIcon && !value && <SearchIcon className="h-4 w-4 shrink-0 text-skeldus-black-150" />}
  </span>
);

export default Search;
