import React from "react";
import { Select as AntdSelect, SelectProps as AntdSelectProps } from "antd";
import classNames from "classnames";
import { Label } from "components/Label";

const { Option } = AntdSelect;

interface SelectOption {
  value: string | number;
  label?: string;
  UserUUID?: string | number;
  taskuuid?: string | number;
}

interface SelectProps extends AntdSelectProps<string | string[] | number | number[]> {
  label?: string;
  prefix?: string;
  options: SelectOption[] | undefined;
  className?: string;
  selectClassName?: string;
}

const Select = ({
  prefix,
  options,
  value,
  className,
  selectClassName,
  defaultValue,
  label,
  ...otherProps
}: SelectProps) => (
  <Label label={label} className={className}>
    <AntdSelect
      className={classNames("skeldus-select", selectClassName)}
      dropdownClassName="skeldus-dropdown"
      placeholder={prefix}
      optionLabelProp="label"
      defaultValue={defaultValue ?? value}
      value={value}
      options={options}
      filterOption={(input, option) =>
        (option?.label as string).toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA.label as string)
          .toLowerCase()
          .localeCompare((optionB.label as string).toLowerCase())
      }
      {...otherProps}
    >
      {options?.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          label={
            <span className="flex items-center gap-2">
              {prefix && <span className="text-skeldus-black-500">{prefix}</span>}
              <span className="text-skeldus-grey-600">{option.label ?? option.value}</span>
            </span>
          }
        >
          {option.label ?? option.value}
        </Option>
      ))}
    </AntdSelect>
  </Label>
);

export default Select;
