export const paths = {
  dashboard: "/",
  users: "/users",
  accounts: "/accounts",
  vendors: "/vendors",
  devices: "/devices",
  cloudEnvironments: "/cloud-assets",
  integrations: "/integrations",
  settings: "/settings",
  settingsCompany: "/settings/company",
  settingsCompanyList: "/settings/company-list",
};
