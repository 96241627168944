import { FormattedMessage } from "react-intl";
import React from "react";
import { Menu } from "antd";
import { paths } from "utils/data/paths";
import { NextRouter } from "next/router";
import { useAppDispatch } from "store/hooks";
import { logout } from "store/application";

interface DropdownMenuProps {
  router: NextRouter;
}

const DropdownMenu = ({ router }: DropdownMenuProps) => {
  const dispatch = useAppDispatch();
  return (
    <Menu theme="light">
      <Menu.Item key="1" onClick={() => router.push(paths.settings)}>
        <FormattedMessage id="medu-dropdown-settings" defaultMessage="Settings" />
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={async () => {
          void dispatch(logout());
          window.location.replace("/login");
        }}
      >
        <FormattedMessage id="medu-dropdown-logout" defaultMessage="Log out" />
      </Menu.Item>
    </Menu>
  );
};

export default DropdownMenu;
