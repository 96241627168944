import {
  BillingDetailsResponse,
  IntegrationAccount,
  PolicyStatus,
  //  Task,
  TaskDetail,
} from "mycio-openapi";
import type { TaskOwnerConfig } from "store/tasks";

export type IntegrationPlatform = "windows" | "mac" | "linux";
export interface IntegrationAgentLink {
  platform: IntegrationPlatform;
  URL: string;
}

export interface Integration {
  type: string;
  title: string;
  description: string;
  status: {
    value: "connected" | "disconnected" | "error" | "partial" | "na";
    message: string;
  };
  tabs?: IntegrationTab[];
  logo: React.ReactElement | string;
  agents?: IntegrationAgentLink[];
  comingSoonImg?: boolean;
  featureFlag?: string;
  integrationSteps?: JSX.Element;
  integrations: IntegrationAccount[];
}

interface IntegrationTab {
  platform: IntegrationPlatform;
  steps: { title: string; text: string; url?: string; code?: string }[];
}

export interface Device {
  device: string;
  compliance: boolean;
  last_seen: string;
  owner: string;
  user: string;
  controls: {
    control_id: string;
    control_compliance: boolean;
  }[];
}

export interface Account {
  account: string;
  compliance: boolean;
  owner: string;
  vendor: string;
  controls: {
    control_id: string;
    control_compliance: boolean;
  }[];
}
export interface Vendor {
  vendor: string;
  compliance: boolean;
  owner: string;
  controls: {
    control_id: string;
    control_compliance: boolean;
  }[];
}

export interface User {
  user: string;
  compliance: boolean;
  devices: string;
  owner: string;
  accounts: string;
  controls: {
    control_id: string;
    control_compliance: boolean;
  }[];
}

export interface Control {
  name: string;
  description: string;
}

export interface LinkedDoc {
  id: string;
  docType: string;
  url: string;
  name: string;
}

export interface FrontendTask extends TaskDetail {
  LinkedDocuments?: LinkedDoc[];
  Owners?: TaskOwnerConfig[];
  remediation?: string;
}

export interface Company {
  name: string;
  uuid: string;
}

export enum SortByOption {
  Status = "status",
  DueDate = "dueDate",
}

export enum TaskFrequency {
  None = "none",
  Monthly = "monthly",
  Biannually = "biannually",
  Annually = "annualy",
}

export enum TaskStatus {
  Completed = "Completed",
  InProgress = "In Progress",
  NotStarted = "Not Started",
  Overdue = "Overdue",
}

export enum UserRole {
  Admin = "admin",
  Standard = "standard",
  Auditor = "auditor",
}

export enum SettingsType {
  User = "user",
  Company = "company",
}

export interface Balance {
  amount: number;
  currency: string;
  bill_date: string;
  due_date: string;
  invoice_name: string;
  invoice_url: string;
}

export type PaymentInformation = BillingDetailsResponse;

export interface PaymentPortal {
  portal_url: string;
}

export interface Policy {
  id?: number;
  title?: string;
  status?: PolicyStatus;
  owner?: string;
  ownerId?: number;
  date?: string;
  version?: string;
  fileUrl?: string;
  description?: string;
}

export interface Document {
  id?: number;
  title?: string;
  date?: string;
  fileUrl?: string;
  description?: string;
}
