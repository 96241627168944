import { FileTextTwoTone, InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import { TaskDrawerSectionProps } from "components/TaskDrawer/TaskDrawer.interface";
import React from "react";
import { getToken } from "store/application";
import { useAppSelector } from "store/hooks";
import { LinkedDoc } from "utils/data/interfaces";
import { downloadEvidence } from "utils/evidence/evidence";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import { DescriptionIcon, PolicyIcon } from "components/icons";

export const iconDefaultClasses = "mr-2 rounded-full w-[25px] h-[25px] p-1 flex justify-center";

const TaskDrawerAdditionalEvidenceSection = ({ task }: TaskDrawerSectionProps) => {
  const token = useAppSelector((state) => getToken(state));

  const parseFileList = (props: LinkedDoc[]) =>
    props.map((doc, id) => ({
      uid: String(id),
      name: doc.name,
      status: "done" as UploadFileStatus,
      url: doc.url,
      type: doc.docType,
    }));

  return (
    <section className="skeldus-upload">
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "side-drawer-additional-evidence-title",
          defaultMessage: "Additional Evidence",
        })}
        icon={<PolicyIcon className={iconDefaultClasses} />}
      >
        <Dragger
          listType="picture"
          disabled={true}
          fileList={
            task.LinkedDocuments ? (parseFileList(task.LinkedDocuments) as UploadFile[]) : undefined
          }
          showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
          isImageUrl={() => false}
          onPreview={(file) => {
            if (file.url) {
              if (file.type === "template") {
                window.open(file.url, "_blank")?.focus();
              } else if (file.type === "evidence") {
                downloadEvidence(file.url, token);
              }
            }
          }}
          iconRender={(file) => {
            if (file.type?.includes("template")) return <FileTextTwoTone />;
            return <DescriptionIcon className="h-9 w-9" />;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag files here to upload evidence</p>
        </Dragger>
      </SideDrawerAccordion>
    </section>
  );
};

export default TaskDrawerAdditionalEvidenceSection;
