import classNames from "classnames";

export interface LabelProps {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  htmlFor?: string;
}

const Label = ({ children, label, className, htmlFor }: LabelProps) => (
  <div className={classNames("flex flex-col content-start", className)}>
    {label && (
      <label htmlFor={htmlFor} className="text-skeldus mb-2 text-sm text-skeldus-black-500">
        {label}
      </label>
    )}
    {children}
  </div>
);

export default Label;
