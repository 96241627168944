import axios from "axios";
import { showLoadingToast, updateToast } from "utils/toasts";
import {
  evidenceDownloadFailureMessage,
  evidenceDownloadLoadingMessage,
  evidenceDownloadSuccessMessage,
} from "utils/toasts/messages";

export const downloadEvidence = (url: string, token: string) => {
  const id = showLoadingToast(evidenceDownloadLoadingMessage);
  axios(`${url}`, {
    method: "GET",
    headers: { authorization: `Bearer ${token}` },
  })
    .then((response) => {
      // Create a Blob from the PDF Stream
      const file = new Blob([Buffer.from(response.data as string, "base64")], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(file);

      updateToast(id, {
        render: evidenceDownloadSuccessMessage,
        type: "success",
        isLoading: false,
      });
      window.open(fileURL);
    })
    .catch(() => {
      updateToast(id, {
        render: evidenceDownloadFailureMessage,
        type: "error",
        isLoading: false,
      });
    });
};
