import React from "react";
import { FormattedMessage } from "react-intl";
import { paths } from "utils/data/paths";
import {
  CloudEnvironmentsIcon,
  ControlIcon,
  UserIcon,
  AccountsIcon,
  VendorsIcon,
  LaptopMobileIcon,
  IntegrationsIcon,
  TasksIcon,
  DocumentIcon,
  VulnerabilityIcon,
  RiskIcon,
  ProgramMapIcon,
  OrganizationIcon,
} from "components/icons";
import { useAppSelector } from "store/hooks";
import { selectSidebarOpen, selectVersion } from "store/application";
import { NavMenuGroup } from "./SidebarMenu.interfaces";
import SidebarMenuGroup from "./SidebarMenuGroup";
import SidebarMenuHeader from "./SidebarMenuHeader";
import SidebarMenuFooter from "./SidebarMenuFooter";

const menuItems: NavMenuGroup[] = [
  {
    items: [
      {
        label: (
          <FormattedMessage
            id="nav-tasks"
            description="tasks navigation label"
            defaultMessage="Tasks"
          />
        ),
        href: "/",
        icon: <TasksIcon />,
      },
    ],
  },
  {
    groupLabel: "Compliance",
    items: [
      {
        label: (
          <FormattedMessage
            id="nav-organization"
            description="organization navigation label"
            defaultMessage="Organization"
          />
        ),
        href: "/organization",
        icon: <OrganizationIcon />,
        featureFlag: "organization",
      },
      {
        label: (
          <FormattedMessage
            id="nav-controls"
            description="controls navigation label"
            defaultMessage="Controls"
          />
        ),
        href: "/controls",
        icon: <ControlIcon />,
        featureFlag: "controls",
      },
      {
        label: (
          <FormattedMessage
            id="nav-documents"
            description="documents navigation label"
            defaultMessage="Documents"
          />
        ),
        href: "/documents",
        icon: <DocumentIcon />,
      },
      {
        label: (
          <FormattedMessage
            id="nav-program-map"
            description="Program Map navigation label"
            defaultMessage="Program Map"
          />
        ),
        href: "/map",
        icon: <ProgramMapIcon />,
      },
    ],
  },
  {
    groupLabel: "Security",
    items: [
      {
        label: (
          <FormattedMessage
            id="nav-vendors"
            description="vendors navigation label"
            defaultMessage="Vendors"
          />
        ),
        href: "/vendors",
        icon: <VendorsIcon />,
        featureFlag: "vendors",
      },
      {
        label: (
          <FormattedMessage
            id="nav-risk-management"
            description="risk-management navigation label"
            defaultMessage="Risk Management"
          />
        ),
        href: "/risk",
        icon: <RiskIcon />,
        featureFlag: "risk",
      },
      {
        label: (
          <FormattedMessage
            id="nav-vulnerabilities"
            description="Vulnerabilities navigation label"
            defaultMessage="Vulnerabilities"
          />
        ),
        href: "/vulnerabilities",
        icon: <VulnerabilityIcon />,
      },
    ],
  },
  {
    groupLabel: "Assets",
    items: [
      {
        label: (
          <FormattedMessage
            id="nav-employees"
            description="employees navigation label"
            defaultMessage="Employees"
          />
        ),
        href: "/employees",
        icon: <UserIcon />,
        featureFlag: "users",
      },
      {
        label: (
          <FormattedMessage
            id="nav-accounts"
            description="accounts navigation label"
            defaultMessage="Accounts"
          />
        ),
        href: "/accounts",
        icon: <AccountsIcon />,
        featureFlag: "accounts",
      },
      {
        label: (
          <FormattedMessage
            id="nav-devices"
            description="devices navigation label"
            defaultMessage="Devices"
          />
        ),
        href: paths.devices,
        icon: <LaptopMobileIcon />,
      },
      {
        label: (
          <FormattedMessage
            id="nav-cloud-assets"
            description="cloud assets navigation label"
            defaultMessage="Cloud Assets"
          />
        ),
        href: "/cloud-assets",
        icon: <CloudEnvironmentsIcon />,
      },
    ],
  },
  {
    groupLabel: "Tools",
    items: [
      {
        label: (
          <FormattedMessage
            id="nav-integrations"
            description="integrations navigation label"
            defaultMessage="Integrations"
          />
        ),
        href: paths.integrations,
        icon: <IntegrationsIcon />,
      },
    ],
  },
];

const SidebarMenu = () => {
  const appVersion = useAppSelector((state) => selectVersion(state));
  const sidebarOpen = useAppSelector((state) => selectSidebarOpen(state));
  return (
    <nav className="relative flex flex-col border-r border-r-skeldus-black-50">
      <div className={`fixed top-0 ${sidebarOpen ? "w-[228px]" : "w-[60px]"}`}>
        <SidebarMenuHeader />
        {menuItems.map((groupItem) => (
          <SidebarMenuGroup key={groupItem.groupLabel ?? groupItem.items[0].href} {...groupItem} />
        ))}
      </div>
      <SidebarMenuFooter version={appVersion} sidebarOpen={sidebarOpen} />
    </nav>
  );
};

export default SidebarMenu;
