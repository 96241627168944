import { TaskDrawerSectionProps } from "components/TaskDrawer/TaskDrawer.interface";
import React from "react";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import CheckListIcon from "components/icons/ChecklistIcon";
import { FormattedMessage } from "react-intl";

export const iconDefaultClasses = "mr-2 rounded-full w-[25px] h-[25px] p-1 flex justify-center";

const TaskDrawerRequirementsSection = ({ task }: TaskDrawerSectionProps) => {
  const requirements = task.control?.requirements;

  return (
    <section className="skeldus-upload">
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "side-drawer-requirements-title",
          defaultMessage: "Requirements",
        })}
        icon={<CheckListIcon className={iconDefaultClasses} />}
      >
        {requirements?.length ? (
          <div className="flex flex-col gap-1 rounded-md bg-skeldus-white p-2">
            {requirements?.map((requirement, key) => (
              <div key={key}>
                <p className="text-[#0664CA]">
                  <span className="font-semibold">{requirement.clause_number}</span>{" "}
                  {requirement.title}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="m-2 ml-0 text-xs text-skeldus-red-700">
            <FormattedMessage
              id="side-drawer-no-requirements"
              defaultMessage="No requirements have been linked to this task"
            />
          </div>
        )}
      </SideDrawerAccordion>
    </section>
  );
};
export default TaskDrawerRequirementsSection;
