import { SidebarMenu } from "components/SidebarMenu";
import { Spinner } from "components/Spinner";
import Head from "next/head";
import React from "react";
import { selectSidebarOpen } from "store/application";
import { useAppSelector } from "store/hooks";
import { IPageContainer } from "./PageContainer.interface";

const PageContainer: React.FC<IPageContainer> = ({ children, title, loadingData, hideSidebar }) => {
  const { tenantConfig } = useAppSelector((state) => state.config);
  const sidebarOpen = useAppSelector((state) => selectSidebarOpen(state));

  return (
    <div
      className={`grid ${sidebarOpen ? "grid-cols-[228px_1fr]" : "grid-cols-[60px_1fr]"} h-screen`}
    >
      <Head>
        {title && (
          <title>
            {tenantConfig.Name ? `${title} - ${tenantConfig.Name}` : `${title}`} - skeldus
          </title>
        )}
      </Head>
      {!hideSidebar && <SidebarMenu />}
      {loadingData ? (
        <Spinner />
      ) : (
        <main className="flex flex-col bg-skeldus-background px-4">{children}</main>
      )}
    </div>
  );
};

export default PageContainer;
