import { CancelIcon, CheckGreenIcon } from "components/icons";

interface SideDrawerAccordionHeaderProps {
  setToggle: (val: boolean) => void;
  toggle: boolean;
  accordionKey: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
  notice?: {
    error: boolean;
    message: string | JSX.Element;
    loading?: boolean;
  };
}

const SideDrawerAccordionHeader = ({
  setToggle,
  toggle,
  accordionKey,
  icon,
  notice,
}: SideDrawerAccordionHeaderProps) => (
  <div
    role="button"
    onClick={() => setToggle && setToggle(!toggle)}
    className={`cursor-pointer} flex items-center justify-between`}
  >
    <div
      className="relative flex items-start justify-between "
      onClick={() => setToggle && setToggle(!toggle)}
    >
      {icon}
      {
        <>
          <h5 className={`w-[180px] capitalize`}>{accordionKey}</h5>

          {notice &&
            (notice.error ? (
              <p className={`flex items-center text-[90%] text-skeldus-main-700`}>
                <CancelIcon className="mr-2 w-[10px] text-[red]" />
                {notice.message}
              </p>
            ) : notice.loading ? (
              <div className="flex items-center">
                <p className={`text-[90%] text-skeldus-black-400`}>{notice.message}</p>
              </div>
            ) : (
              <p className={`flex items-center text-[90%] text-skeldus-green-800`}>
                <CheckGreenIcon className="mr-2 w-[10px]" />
                {notice.message}
              </p>
            ))}
        </>
      }
    </div>
    {
      <span
        className={`toggle-caret ${
          toggle ? "toggle-open" : ""
        } h-[12px] w-[12px] before:h-[1.5px] before:w-[8px] after:h-[1.5px] after:w-[8px] `}
      />
    }
  </div>
);

export default SideDrawerAccordionHeader;
