interface SpinnerProps {
  showLabel?: boolean;
}

export default function Spinner({ showLabel }: SpinnerProps) {
  return (
    <div className="absolute left-1/2 top-1/2 m-auto flex translate-x-0.5 translate-y-0.5 flex-col justify-center">
      <div
        className="border-t-transparent h-8 w-8 animate-spin
        rounded-full border-x-2 border-solid border-skeldus-black-300"
      ></div>
      {showLabel && <p className="mt-2">loading</p>}
    </div>
  );
}
