import { SidebarIcon } from "components/icons";
import { MyCIOLogo } from "components/logos";
import { setSidebarOpen } from "store/application";
import { useAppDispatch } from "store/hooks";
import { VersionDetails } from "mycio-openapi";

interface SidebarMenuFooterProps {
  version: VersionDetails;
  sidebarOpen: boolean;
}

const SidebarMenuFooter = ({ version, sidebarOpen }: SidebarMenuFooterProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className={`fixed bottom-0 mb-4 mt-auto ${sidebarOpen ? "mx-4 w-[200px]" : "w-14"}`}>
      {sidebarOpen && process.env.NEXT_PUBLIC_ENVIRONMENT === "sandbox" && version.Version?.branch && (
        <div className="mb-4 text-xs text-skeldus-black-300">
          <div>{"API VERSION:"}</div>
          <div className="text-ellipsis">{`Branch: ${version.Version.branch.substring(
            version.Version.branch.lastIndexOf("/") + 1,
            version.Version.branch.length
          )}`}</div>
        </div>
      )}
      <div className={`flex items-center justify-between`}>
        <MyCIOLogo variant={sidebarOpen ? "positive" : "symbol"} size="small" />
        <div
          onClick={() => {
            void dispatch(setSidebarOpen(!sidebarOpen));
          }}
        >
          <SidebarIcon
            color="#4f555c"
            className={`h-3 w-3 cursor-pointer ${sidebarOpen ? "" : "rotate-180"}`}
          />
        </div>
      </div>
    </div>
  );
};

export default SidebarMenuFooter;
