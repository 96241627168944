import React from "react";
import { selectFeatureFlag } from "store/featureFlags";
import { useAppSelector } from "store/hooks";

interface FeatureFlagProps {
  feature: string;
  children: React.ReactNode;
}

const FeatureFlag = ({ feature, children }: FeatureFlagProps) => {
  const showFeature = useAppSelector((state) => selectFeatureFlag(state, feature));

  if (showFeature === undefined) return <>{children}</>;

  return <>{showFeature ? children : null}</>;
};

export default FeatureFlag;
