import { DatePicker } from "antd";
import { Label } from "components/Label";
import { Select } from "components/Select";
import { TaskDrawerSectionProps } from "components/TaskDrawer/TaskDrawer.interface";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUsersFilter, selectUsersFilter } from "store/users";
import { taskStatusOptions } from "utils/data/options";
import { updateTaskOwner } from "store/tasks";
import { TaskDetailStatusEnum } from "mycio-openapi";
import { intl } from "utils/intl";

type UserOption = {
  id: string;
  value: string;
  label: string;
};

const TaskDrawerDetailsSection = ({ task, onEdit }: TaskDrawerSectionProps) => {
  const [usersSearchKeyword, setUsersSearchKeyword] = useState<string>("");
  const { users, usersIsLoading } = useAppSelector((state) => selectUsersFilter(state));
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getUsersFilter(usersSearchKeyword));
  }, [dispatch, usersSearchKeyword]);

  const updateDueDate = (value: Moment | null) => {
    if (value && onEdit) onEdit({ due_date: value.toISOString() });
  };
  const updateOwners = async (options: UserOption) => {
    const ownerId = Number(options?.id);
    if (task?.id && ownerId) await dispatch(updateTaskOwner({ uuid: task?.id, ownerId }));
  };

  return (
    <section>
      <div className="my-4 flex flex-wrap items-center gap-4">
        <Select
          label={intl.formatMessage({
            id: "task-details-update-owner",
            defaultMessage: "Task Owner",
          })}
          className="w-fit"
          onChange={async (_, option) => {
            await updateOwners(option as UserOption);
          }}
          showSearch
          placeholder="Select owner"
          options={
            users?.map((user) => ({
              id: user?.id,
              value: user?.id as number,
              label: user?.email || "",
            })) || []
          }
          onSearch={(e) => setUsersSearchKeyword(e)}
          loading={usersIsLoading}
          defaultValue={task.owners?.user_name ?? undefined}
        />
        <Label label="Due date">
          <DatePicker
            name="duedate"
            className="h-[34px]"
            onChange={updateDueDate}
            defaultValue={task.due_date ? moment(task.due_date) : moment()}
          />
        </Label>
        <Select
          label="Status"
          onChange={(value) => {
            onEdit?.({
              status: value as TaskDetailStatusEnum,
            });
          }}
          value={task.status}
          options={taskStatusOptions}
        />
      </div>
      {/* <div className="mt-4 flex flex-wrap items-center gap-2">
        {task.Tags &&
          task.Tags.map((tag, uuid) => <Tag key={uuid} content={`${tag.Key}: ${tag.Value}`} />)}
      </div> */}
    </section>
  );
};

export default TaskDrawerDetailsSection;
