import { IconProps } from "./Icon.interface";

const LoadingIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill="none" strokeOpacity="1" stroke="#000000" strokeWidth=".5" cx="100" cy="100" r="0">
      <animate
        attributeName="r"
        calcMode="spline"
        dur="2"
        values="1;80"
        keyTimes="0;1"
        keySplines="0 .2 .5 1"
        repeatCount="indefinite"
      ></animate>
      <animate
        attributeName="stroke-width"
        calcMode="spline"
        dur="2"
        values="0;25"
        keyTimes="0;1"
        keySplines="0 .2 .5 1"
        repeatCount="indefinite"
      ></animate>
      <animate
        attributeName="stroke-opacity"
        calcMode="spline"
        dur="2"
        values="1;0"
        keyTimes="0;1"
        keySplines="0 .2 .5 1"
        repeatCount="indefinite"
      ></animate>
    </circle>
  </svg>
);

export default LoadingIcon;
