import ChevronIcon from "components/icons/ChevronIcon";
import { useAppSelector } from "store/hooks";
import { Dropdown } from "antd";
import React from "react";
import DropdownMenu from "components/SidebarMenu/DropdownMenu";
import { useRouter } from "next/router";
import { Avatar } from "components/Avatar";
import { selectCurrentUser, selectSidebarOpen } from "store/application";

const SidebarMenuHeader = () => {
  const userConfig = useAppSelector(selectCurrentUser);
  const currentUserAvatar = useAppSelector((state) => state.users.currentUserAvatar);
  const sidebarOpen = useAppSelector((state) => selectSidebarOpen(state));
  const router = useRouter();

  return (
    <div className="mx-4 flex h-[64px] flex-row items-center justify-between">
      {sidebarOpen && (
        <h3 className="m-0 text-base font-semibold text-skeldus-black-500">
          {userConfig.data?.name}
        </h3>
      )}
      <Dropdown
        className="text-skeldus-black-50"
        overlay={DropdownMenu({ router })}
        placement="bottomLeft"
        arrow
      >
        <div className="flex items-center gap-1">
          <Avatar
            size="small"
            fallbackUserName={userConfig.data?.name}
            avatar={userConfig.data?.avatar || currentUserAvatar}
          />
          {sidebarOpen && <ChevronIcon />}
        </div>
      </Dropdown>
    </div>
  );
};

export default SidebarMenuHeader;
