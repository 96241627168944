import { TaskDrawerSectionProps } from "components/TaskDrawer/TaskDrawer.interface";
import React, { useState } from "react";
import dynamic from "next/dynamic";
import { EditorProps } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SideDrawerAccordion } from "components/SideDrawerAccordion";
import { intl } from "utils/intl";
import { DescriptionIcon } from "components/icons";
import { EditTwoTone } from "@ant-design/icons";
import { iconDefaultClasses } from "./TaskDrawerEvidenceSection";

const Editor = dynamic<EditorProps>(() => import("react-draft-wysiwyg").then((mod) => mod.Editor), {
  ssr: false,
});

const TaskDrawerDescriptionSection = ({ task, onEdit }: TaskDrawerSectionProps) => {
  const [descriptionEditState, setDescriptionEditState] = useState(false);
  const descriptionContentBlock = htmlToDraft(task.description);
  const descriptionContentState = ContentState.createFromBlockArray(
    descriptionContentBlock.contentBlocks
  );
  const [descriptionState, setDescriptionState] = useState(
    EditorState.createWithContent(descriptionContentState)
  );

  const updateDescription = (props: EditorState) => {
    setDescriptionState(props);
    onEdit?.({
      description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
    });
  };

  return (
    <section>
      <SideDrawerAccordion
        accordionKey={intl.formatMessage({
          id: "side-drawer-description-title",
          defaultMessage: "Description",
        })}
        icon={<DescriptionIcon className={iconDefaultClasses} />}
      >
        <Editor
          wrapperStyle={{
            minHeight: "10vh",
            width: "100%",
            backgroundColor: descriptionEditState ? "white" : "transparent",
          }}
          editorStyle={{
            overflow: "hidden",
          }}
          editorState={descriptionState}
          onEditorStateChange={updateDescription}
          toolbar={{
            options: ["inline", "list", "link", "emoji"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline"],
            },
            list: { inDropdown: false, options: ["unordered", "ordered"] },
            link: { inDropdown: false },
          }}
          toolbarHidden={!descriptionEditState}
          readOnly={!descriptionEditState}
        />
        <div className="flex w-full justify-end">
          <button
            onClick={() => {
              setDescriptionEditState((state) => !state);
            }}
          >
            <EditTwoTone />
          </button>
        </div>
      </SideDrawerAccordion>
    </section>
  );
};

export default TaskDrawerDescriptionSection;
